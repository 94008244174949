import { Currency } from '@/common/types/price-types'
import { formatPrice } from '@/common/utils/price-utils'
import { useStoreContext } from '@/providers'

interface UnitPriceProps {
  unitPrice: {
    value: number
    unit: string
  }
  currency?: Currency | null
}

export const UnitPrice = ({ unitPrice, currency }: UnitPriceProps) => {
  const { storeCode, storeConfig } = useStoreContext()

  const unit = unitPrice.unit ? unitPrice.unit.split('/')[1] : undefined

  const formattedUnitPrice = formatPrice({
    currency,
    storeCode,
    price: unitPrice.value,
  })

  const dualCurrency = {
    rate: storeConfig.checkoutCurrency?.rate,
    format: storeConfig.checkoutCurrency?.format,
  }

  const formattedDualPrice =
    dualCurrency?.rate && dualCurrency?.format
      ? formatPrice({
          storeCode,
          price: (unitPrice.value ?? 0) * dualCurrency.rate ?? 0,
          currencyFormat: dualCurrency.format,
          dualCurrency: true,
        })
      : undefined

  return (
    <div className="mb-1">
      <span>
        {formattedUnitPrice}
        {dualCurrency?.format && formattedDualPrice && (
          <span className="text-grey-300 ml-1 text-sm font-normal">
            ~({formattedDualPrice})
          </span>
        )}
        /{unit || unitPrice.unit}
      </span>
    </div>
  )
}
